import { render, ChangeEvent } from 'inferno'
import { createElement } from 'inferno-create-element'

import { ManageTable } from '@/components/managetable'
import { DynamicRoot } from '@/components/dynamic'
import { Toaster, IToast } from '@/components/toaster'
import { asyncComponent } from '@/components/asynccomponent'

import 'url-polyfill'

declare global {
  interface Window {
    Toaster: {
      addToast: (toast: IToast) => void
    }
  }
}

export function bootstrap() {
  console.debug('Initializing components...')

  bootstrapDynamic()
  bootstrapManageTable()
  bootstrapToaster()

  console.groupEnd();
}

function bootstrapDynamic() {
  const componentElements = document.querySelectorAll('div[data-component="dynamic"]')

  console.debug('[Dynamic component] Elements found --> ', componentElements)

  let fetchUrl:string|null
  let componentElement:Element

  if (componentElements) {
    for (let i=0; i < componentElements.length; i++) {
      componentElement = componentElements[i]
      fetchUrl = componentElement.getAttribute('data-fetch-url');

      if (fetchUrl) {
        render(<DynamicRoot fetchUrl={fetchUrl} />, componentElement)
      }
    }
  }
}

// Looks for an element <div data-component="managetable" data-url="..."></div>
function bootstrapManageTable() {
  const componentElements = document.querySelectorAll('div[data-component="managetable"]')

  console.debug('[Manage table] Elements found --> ', componentElements)

  let fetchUrl:string|null
  let componentElement:Element

  if (componentElements) {
    for (let i=0; i < componentElements.length; i++) {
      componentElement = componentElements[i]
      fetchUrl = componentElement.getAttribute('data-fetch-url') as string;
      return render(createElement(asyncComponent(ManageTable, {}, fetchUrl)), componentElement)
    }
  }
}

function bootstrapToaster() {
  if (!window.Toaster) {
    const toasterContainer = document.createElement('div')
    toasterContainer.setAttribute('data-component', 'toaster')
    document.body.appendChild(toasterContainer)

    render(<Toaster duration={5}/>, toasterContainer)
  }
}
