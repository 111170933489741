import classNames from 'classnames'
import { createElement } from 'inferno-create-element'

declare global {
  interface Window {
    M: any
  }
}

export interface IAction {
  name?: string
  text?: string
  type?: string
  url?: string
  isIcon?: boolean
  icon?: string
  isPrimary?: boolean
  isSecondary?: boolean
  isDisabled?: boolean
  confirmText?: string
  data?: any
}

export type ActionMap = {
  [key: string]: IAction
}

export type IHandler = (e: Event, action: IAction, itemId?: number) => void

export type ActionListType = "buttons"|"icons"|"dropdown"

export const ActionList = ({
  itemId, actions, handler, type = "buttons"
}: {
  itemId?: number
  actions: ActionMap
  handler: IHandler
  type?: ActionListType
}) => (
  <ul className={`action-list action-list--${type} list-inline`}>
    {
      Object.keys(actions).map(key => {
        const action = actions[key]
        switch (type) {
          //case 'dropdown': return <li><ActionDropdown action={action} handler={handler} /></li>
          default:         return <li className="list-inline-item"><ActionButton itemId={itemId} name={key} action={action} handler={handler} /></li>
        }
      })
    }
  </ul>
)

export const ActionButton = ({
  itemId, name, action, handler
}: {
  itemId?: number
  name: string
  action: IAction
  handler: IHandler
}) => {
  const isDisabled = action.isDisabled === true

  const classes = classNames({
    'btn': !action.isIcon,
    'btn-primary': !action.isIcon && action.isPrimary,
    'btn-secondary': !action.isIcon && action.isSecondary,
    'text-primary': action.isIcon && action.isPrimary,
    'text-secondary': action.isIcon && action.isSecondary,
    'disabled': isDisabled
  })

  const props:{
    'data-action': string
    'data-icon'?: string
    title?: string
    onClick?: (e: Event) => void
    href?: string
  } = {
    'data-action': name,
    'data-icon': action.icon,
    title: action.text,
  }

  switch (action.type) {

    case 'actionlist': {
      props.href = `#${action.name}`
      props.onClick = (e) => {
        e.preventDefault()

        window.M.theme_soon?.ModalManager?.showModal(action.data, {
          title: action.text,
          type: 'actionlist',
          handler: (e: Event, action) => handler(e, action, itemId)
        })
      }

      const text = action.isIcon ? '' : action.text

      return (
          <a className={classes} {...props}>
            { action.icon ? ( <i class={`fa fa-${action.icon}`}></i> ) : null }
            { text }
          </a>
        )
    }

    default: {
      if (!isDisabled) {
        props.href = action.url
        props.onClick = (e: Event) => handler(e, action, itemId)
      }

      const text = action.isIcon ? '' : action.text

      return isDisabled ? (
        <span className={classes} {...props}>
          { action.icon ? ( <i class={`fa fa-${action.icon}`}></i> ) : null }
          { text }
        </span>
      ) : (
        <a className={classes} {...props}>
          { action.icon ? ( <i class={`fa fa-${action.icon}`}></i> ) : null }
          { text }
        </a>
      )
    }
  }
}
